/* Mobile & Tablet */
@media (max-width: 1023px) {
    .member__list {
        max-width: 70%;
    }
    .member-page__list-item {
        padding: 10% 8%;
    }

    .member-page__list-item-bg-img {
        max-height: 20px;
        background-color: red;
    }
}
/* tablet only */
@media (min-width: 740px) and (max-width: 1023px) {
    .member__list {
        max-width: 60%;
    }
}

/* Mobile */
@media (max-width: 739px) {
    .member__list {
        max-width: 90%;
    }
}

@media (min-width: 1024px) and (max-width: 1239px) {
    .member__list {
        max-width: 80%;
    }
}
