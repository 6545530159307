.button-primary
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    border: none
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    color: #FCFCFD
    cursor: pointer
    transition: all 0.2s ease
    padding: 9px 18px
    gap: 8px
    min-width: 108px
    width: 100%
    height: 36px
    background: #326FF0
    border-radius: 4px
    height: 100%

    &:hover
        background: #84BCFF
        transform: translateY(-1px)
