.select-wrapper
    font-family: sans-serif
    min-width: 90px
    width: 100%
    height: 100%
    background: #fff
    border-radius: 4px

    position: relative
    // cursor: default;
    z-index: 1000
.select
    top: 50px
    min-width: 90px
    height: 100%
    margin: 0 auto
    cursor: pointer
    &__header
        background: #fcfcfd
        display: flex
        justify-content: space-between
        align-items: center
        font-style: normal
        min-width: 90px
        width: 100%
        height: 100%
        border: 1px solid #f4f5f6
        border-radius: 4px
        padding: 0 8px
        font-family: 'Inter'
        font-weight: 500
        font-size: 14px
        line-height: 16px
        color: #000000
        align-content: center
    &__list
        padding: 0
        margin-top: 10px
        background: #fcfcfd
        box-sizing: border-box
        font-weight: 500
        min-width: 90px
        border: 1px solid #f4f5f6
        // height: 32px
        padding: 0 8px
        font-family: 'Inter'
        font-weight: 500
        font-size: 14px
        line-height: 16px
        color: #000000
.select__list-item
    list-style: none
    font-family: 'Inter'
    font-style: normal
    display: flex
    justify-content: flex-start
    align-items: center
    cursor: pointer
    width: 100%
    height: 32px
    background: #fcfcfd
    font-weight: 500
    font-size: 14px
    line-height: 16px
    color: #000000
