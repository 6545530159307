.full-page-loading
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 999
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.3
