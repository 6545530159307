@import-normalize
body
    margin: 0
    font-family: 'Inter','Open Sans','SF Pro Display','Roboto','Poppins', sans-serif
    background-color: #F4F5F6
    overflow-x: hidden
::-webkit-scrollbar
    width: 5px

/* Track */
::-webkit-scrollbar-track
    background: #f1f1f1

/* Handle */
::-webkit-scrollbar-thumb
    background: #999

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
    background: #888

a
    text-decoration: none

html
    font-size: 62.5%
    scroll-behavior: smooth

iframe
    display: none

.flex-col-between
    display: flex
    justify-content: space-between
    flex-direction: column

.flex-height-center
    display: flex
    align-items: center

.flex-center
    display: flex
    align-items: center
    justify-content: center

.menu-hover
    height: 100px
    &:hover
        background-color: rgb(28, 27, 27) !important
.text-grey-300
    color: #646464

.text_over_flow_2
    display: -webkit-box !important
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
.text_over_flow_1
    display: -webkit-box !important
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
.pd-8
    padding: 8px

.is-flex
    display: flex

.al-center
    align-items: center

.ju-center
    justify-content: center

.cl-white
    color: #fff

.hover-pointer
    &:hover
        cursor: pointer
.b4E__text
    display: inline
    user-select: none
    & div
        color: #fff
        font-size: 26px
        position: absolute
        left: 0

    & div:nth-child(1)
        color: transparent
        -webkit-text-stroke: 1px #03a9f4

    & div:nth-child(2)
        color: #03a9f4
        animation: animate 4s ease-in-out infinite

@keyframes animate
    0%,
    100%
        clip-path: polygon( 0% 45%, 16% 44%, 33% 50%, 54% 60%,70% 61%,84% 59%, 100% 52%,100% 100%,0% 100%)

    50%
        clip-path: polygon( 0% 60%, 15% 65%,34% 66%,51% 62%,67% 50%,84% 45%,100% 46%,100% 100%,0% 100%)
