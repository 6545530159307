
#footer
    padding-top: 80px
    // margin-top: 80px
    background: #FFFFFF
    width: 100%

.footer
    height: 123px
    border-top: 1px solid #D9D9D9
    border-bottom: 1px solid #D9D9D9
    margin: 0 auto
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    padding: 0px
    width: 80%

    &-sec2
        display: flex
        justify-content: space-around
        padding: 0 50px
        width: 50%

    &__logo
        padding-right: 15px
        height: 32px
        display: flex
        cursor: pointer
        justify-content: space-between
        &-logo
            width: 30px
            height: 32px
        &-bkc-text
            width: 112px
            height: 32px
            left: 39px
            top: 0px
            font-family: 'Inter'
            font-style: normal
            font-weight: 700
            font-size: 24px
            line-height: 32px
            color: #000000

    &__block
        display: flex
        flex-direction: column
        justify-content: space-around
        min-width: 64px
        height: 90px
        &-item
            height: 16px
            font-family: 'Inter'
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 16px
            display: flex
            align-items: center
            color: #777E91
            &-text
                // display: block
            // &--bold
            //     // font-family: 'SF Pro Text'
            //     font-style: normal
            //     font-weight: 400
            //     font-size: 14px
            //     line-height: 160%
            //     color: #424245
.copyright
    height: 28px
    width: 100%
    font-size: 14px
    text-align: center
    line-height: 24px
    color: #777E91
