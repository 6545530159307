@keyframes back
    100%
        background-position: 3000px 0
@keyframes aniDown
    0%
        opacity: 0.9
        height: 0

    100%
        opacity: 1
        height: 100vh
@keyframes aniUp
    0%
        height: auto

    100%
        height: 0px

$backImgPath: "/assets/images"

#introduction_a
    display: flex
    justify-content: center

    // margin-top: 90
.introduction
    width: 100vw
    height: 778px
    &__image
        position: absolute
        // width: 1440px
        width: 100vw
        min-height: 501px
        // height: 778px
        // top: 90px
        // object-fit: contain

    &__background
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.73) 33.33%, rgba(217, 217, 217, 0) 100%)
        // width: 1140
        width: 100vw
        height: 778px
        position: relative
        top: 0
        right: 0
        &-text
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 0px
            gap: 12px
            position: absolute
            // width: 811px
            width: 50%
            // height: 266px
            left: 160px
            top: 270px

            &-heading
                font-family: 'Inter'
                // width: 811px
                // height: 158px
                font-style: normal
                font-weight: 700
                font-size: 6.4rem
                line-height: 79px
                letter-spacing: -0.02em
                color: transparent
                -webkit-text-stroke: 1px #FCFCFD
                background: url(#{$backImgPath}/back.png)
                -webkit-background-clip: text
                background-position: 0 0
                animation: back 20s linear infinite

            &-detail
                max-width: 526px
                // height: 96px
                font-style: normal
                font-weight: 500
                font-size: 1.6rem
                line-height: 24px
                color: #FCFCFD
#solutions_a
    display: flex
    flex-direction: column
    align-items: center
    padding: 0px
    gap: 80px
    // width: 1441px
    margin: 0 auto
    // margin-top: 74px
    padding-top: 90px
.solutions
    display: flex
    flex-direction: column
    align-items: center
    padding: 0px
    gap: 40px
    // width: 1120px
    // height: 1321px
    &__heading
        // width: 400px
        height: 48px
        font-style: normal
        font-weight: 700
        font-size: 40px
        line-height: 48px
        letter-spacing: -0.01em
        color: #141416
        margin: 0
    &__list
        display: flex
        flex-direction: row
        align-items: flex-start
        padding: 0px
        gap: 32px
        // width: 1120px
        // height: 1233px
        &-item
            // padding: 40px 28px 0 28px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            margin: 0 auto
            width: 50%
            width: 544px
            padding: 7.35% 9.19% 0px
            gap: 10px
            margin-bottom: 24px
            // width: 542px
            height: 555px

            background: #FFFFFF
            box-shadow: 0px 0px 20px 8px rgba(15, 15, 15, 0.12)
            border-radius: 12px

            transition: all 0.3s ease
            &-wrapper
                margin: 0 auto
            &-top
                display: flex
                flex-direction: column
                align-items: center
                padding: 0px
                gap: 12px
                width: 421px
                height: 150px
                &-heading
                    margin: 0
                    // width: 90px
                    height: 40px
                    font-style: normal
                    font-weight: 700
                    font-size: 32px
                    line-height: 40px
                    display: flex
                    align-items: center
                    text-align: center
                    letter-spacing: -0.01em
                    color: #23262F
                &-descriptions
                    width: 380px
                    // height: 54px
                    font-style: normal
                    font-weight: 400
                    font-size: 18px
                    line-height: 27px
                    text-align: center
                    letter-spacing: -0.01em
                    color: #353945
                &-text
                    display: flex
                    flex-direction: row
                    align-items: center
                    padding: 0px
                    gap: 4px
                    // width: 96px
                    height: 24px
                    line-height: 24px
                    flex: none
                    order: 2
                    flex-grow: 0
                    position: relative
                    // transition: all
                    &:hover
                        &:after
                            content: ""
                            width: 80px
                            height: 1px
                            background-color: #6bb7ff
                            position: absolute
                            transform: translateX(-50%)
                            left: 45%
                            bottom: 0

            &-img
                width: 486px
                height: 360px
            &:hover
                transform: scale(1.01)
                box-shadow: 0px 0px 20px 8px rgba(107, 207, 234, 0.12)

.solution-b4e
    height: 622px
.solution-bagri
    height: 646px

.solution-vchain
    height: 478px

#publications
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 80px 120px
    gap: 10px
    // width: 1440px
    // height: 985px
    // background: #F4F5F6

#member_a
    // margin-top: 80px
    padding-top: 90px
    display: flex
    justify-content: center

.member
    display: flex
    flex-direction: column
    align-items: center
    padding: 0px
    gap: 60px
    // width: 1118px
    // height: 596px
    margin-bottom: 80px

    &__heading
        // width: 330px

        height: 48px
        font-style: normal
        font-weight: 700
        font-size: 40px
        line-height: 48px
        letter-spacing: -0.01em
        color: #141416
        text-align: center
    &__list
        &-item
            position: relative
            display: flex
            justify-content: center
            flex-direction: row
            align-items: flex-start
            margin: 0 auto
            padding: 14px 10px
            gap: 10px
            width: 256px
            height: 400px
            background: #FFFFFF
            border-radius: 12px
            transition: all 0.3s ease

            &-bg-img
                display: flex
                justify-content: center
                align-items: flex-end
            &-img
                position: absolute
                // width: 152.6px
                height: 218.73px

            &-bg-img
                position: absolute
                width: 202px
                height: 235px
                left: 25px
                top: 13px
                background: #CACACA
                border-radius: 10px
            &-info
                position: absolute
                top: 269.94px
                display: flex
                justify-content: center
                flex-direction: column
                &-name
                    width: 142px
                    height: 16px
                    font-style: normal
                    font-weight: 700
                    font-size: 16px
                    line-height: 16px
                    color: #141416
                    margin: 0 auto
                    margin-bottom: 14px
                    text-align: center

                &-position
                    // width: 75px
                    height: 14px
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 14px
                    color: #326FF0
                    margin: 0 auto
                    margin-bottom: 14px

                &-description
                    width: 219px
                    height: 48px
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 24px
                    text-align: center
                    color: #141416
                    margin: 0
            &:hover
                // width: 256px
                // height: 338px
                background: #FFFFFF
                // border: 1px solid #F4F5F6
                box-shadow: 0px 46px 64px -48px rgba(15, 15, 15, 0.5)
        &-btn
            display: flex
            width: 108px
            justify-content: center
            margin: 0 auto
            margin-top: 66px

#publications_a
    display: flex
    flex-direction: column
    align-items: flex-start
    // padding: 80px 120px
    gap: 10px
    // width: 1440px
    width: 100%
    // height: 985px
    background: #F4F5F6

.publications
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 40px 40px
    gap: 10px
    // width: 1199px
    width: 80%
    margin: 0 auto
    // height: 825px
    background: #FFFFFF
    // box-shadow: 0px 46px 64px -48px rgba(15, 15, 15, 0.1)
    border-radius: 12px
    margin-top: 50px

    &__heading
        margin: 0 auto
        margin-bottom: 50px
        width: 599px
        height: 48px
        font-style: normal
        font-weight: 700
        font-size: 40px
        line-height: 48px
        letter-spacing: -0.01em
        color: #141416
        text-align: center
    &__list
        width: 100%
        // height: 521px
        margin: 0 auto
        &-left
            // width: 480px
            width: 100%
            height: 521px
            margin: 0 auto
            &-img
                // width: 480px
                width: 100%
                padding: 0
                margin: 0
                height: 292px
            &-heading
                // width: 480px
                width: 100%
                margin: 20px 0px 8px 0
                &-title
                    margin-right: 27px
                    height: 48px
                    font-family: 'SF Pro Display'
                    font-style: normal
                    font-weight: 600
                    font-size: 18px
                    line-height: 24px
                    color: #353945
                    margin-bottom: 8px
                    cursor: pointer

                &-time
                    display: block
                    height: 24px
                    font-family: 'Open Sans'
                    font-style: normal
                    font-weight: 300
                    font-size: 14px
                    line-height: 24px
                    color: #23262F
            &-description
                display: block
                width: 90%
                height: 96px
                font-family: 'Inter'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 24px
                color: #777E91
                margin-right: 27px

    &__btn
        // width: 100%
        width: 192px
        display: flex
        justify-content: flex-end
        margin: 0 auto
        margin-top: 50px

.publications__list
    width: 100%
    &-right
        // width: 546pxw
        width: 100%
        height: 139px
        display: flex
        &-img
            max-width: 162px
            width: 30%
            // height: 139px    
            width: 86%
            margin-right: 24px
            object-fit: cover
        &-info
            width: 100%
            display: flex
            flex-direction: column
            &-position
                font-family: 'SF Pro Display'
            &-heading
                // width: 359px
                width: 100%
                // height: 48px
                left: 186px
                top: 0px
                font-family: 'SF Pro Display'
                font-style: normal
                font-weight: 600
                font-size: 1.8rem
                line-height: 2.4rem
                color: #353945
                margin-bottom: 3px
                overflow: hidden
                cursor: pointer
                display: -webkit-box
                // max-width: 100%
                // margin: 0 auto
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden
                text-overflow: ellipsis
            &-time
                display: block
                width: 100%
                height: 24px
                left: 185px
                top: 51px
                font-family: 'Open Sans'
                font-style: normal
                font-weight: 300
                font-size: 14px
                line-height: 24px
                color: #23262F
                margin-bottom: 8px
            &-description
                // display: block
                // width: 360px 
                width: 100%
                height: 48px
                left: 186px
                top: 83px
                font-family: 'Inter'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 24px
                color: #777E91
                display: -webkit-box
                max-width: 100%
                // margin: 0 auto
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden
                text-overflow: ellipsis
        &-line
            width: 543px
            // height: 1px
            // background-color: red    
            margin: 20px 0
            border: 0.5px solid #CCCCCC

/*Responsive*/
/* Mobile & Tablet */
@media (max-width: 1023px)
    .member__list
    #header-wrapper
        width: 100vw
        justify-content: flex-start
        // padding-left: 32px
    .header__nav-item-btn-mobile
        width: 80%
        background-color: transparent
        padding: 0

        > .select-wrapper > .select > .select__header
            background-color: transparent
            border: none
            padding: 0
            font-weight: 500
            font-size: 14px
            line-height: 24px
            color: #777E91
        .select__list-item
            height: 54px
            font-weight: 500
            font-size: 14px
            line-height: 24px
            color: #777E91
        .select__list-item,
        .select__list
            background-color: transparent
            border: none
            padding: 0

    .header
        width: 100%
        padding: 0 32px
        &-nav-wrapper
            display: block
            transition: all .2s ease-in
            -webkit-transition: all .2s ease-in
        &__menu
            width: 100%
            display: flex
            justify-content: space-between
            transition: all 1000ms ease

            &-menu
                width: 74.21px
                display: flex
                align-items: center

                &-test
                    &-stick1
                        width: 70%
                        height: 2px
                        border-radius: 5px
                        background-color: black
                        position: absolute
                        transition: all 0.2s ease
                        top: 7px
                        z-index: 99999

                    &-stick2
                        width: 70%
                        height: 2px
                        border-radius: 5px
                        background-color: black
                        position: absolute
                        transition: all 0.2s ease
                        top: 15px
                        z-index: 99999

                    &-stick1.active
                        transform: rotateZ(-135deg)
                        top: 10.5px

                    &-stick2.active
                        top: 10.5px
                        transform: rotateZ(135deg)

            &-logo
                display: block
                width: 26px
                height: 28px

            &-bkc-text
                font-size: 1.6rem
                line-height: 3.2rem
                font-family: 'Inter'
                font-weight: 700
                color: #000000

        &__nav
            position: absolute
            top: 90px
            left: 0
            width: 100%
            height: 100vh
            background-color: #fff
            display: flex
            flex-direction: column
            padding-left: 64px
            padding-top: 16px
            gap: 0
            grid-gap: 0
            transition: all .2s ease-in
            -webkit-transition: all .2s ease-in
            z-index: 10
            // animation: aniName 1s infinite
            // animation-iteration-count: 1

            &-item
                position: relative
                height: 54px
                display: flex
                align-items: center
                justify-content: flex-start
                &::after
                    position: absolute
                    bottom: 0
                    left: 0
                    content: ""
                    width: 239px
                    height: 1px
                    background-color: #F4F5F6
                    cursor: pointer
        &__bkc-logo
            display: none
            width: 140px
            // display: flex
            justify-content: space-between
            &-logo

            &-bkc-text

        &__select
            display: none
    .footer
        display: flex
        flex-direction: column
        // align-items: flex-start
        height: 242px
        width: 90%
        padding: 30px 0
        gap: 0
        grid-gap: 0
        &__logo
            padding: 0
            width: 25px
            height: 32px
            display: flex
            align-items: center
            &-logo
                width: 20px
                height: 22px
            &-bkc-text
                font-size: 1.4rem
        &-sec2
            width: 100%
            padding: 0
        &__block
            height: 170px
            &-item
                font-size: 1.4rem
                line-height: 1.6rem

/* tablet only */
@media (min-width: 740px) and (max-width: 1023px)
    .introduction__background-text
        top: 100px
        left: 80px
        &-heading
            font-size: 4.2rem
            width: 120%
            line-height: 4.4rem
            height: auto
    .introduction
        height: 400px

        &__image
            height: 400px
        &__background
            height: 400px
    .image-slider
        height: 400px

    .member__list
        max-width: 60%
    .member
        grid-gap: 30px
        gap: 30px
        &__heading
            font-size: 3.2rem
            margin: 0

    .publications__heading
        font-size: 3.2rem
        margin-bottom: 24px
    .solutions
        &__heading
            font-size: 3.2rem
            line-height: 4rem
            width: 100%
            text-align: center
        &__list
            &-item-wrapper
                margin: 0 auto
                padding: 8px
            &-item
                margin: 0 auto
                width: 100%
                height: auto
                max-height: 410px
                gap: 0
                grid-gap: 0
                margin-bottom: 24px
                &-top
                    width: 295px
                    height: 107px
                    gap: 0
                    &-heading
                        width: 100%
                        display: block
                        text-align: center
                        font-size: 2rem
                        line-height: 4rem
                    &-descriptions
                        width: 100%
                        font-size: 1.6rem
                        line-height: 1.6rem
                    &-text
                        font-size: 1.4rem
                        line-height: 1.6rem
                        display: block
                &-img
                    display: block
                    width: 311px
                    height: 248px

/* Mobile */
@media (max-width: 739px)

    .member__list
        max-width: 90%
        &-btn
            // margin-top: 
        &-item
            margin-bottom: 15px

    .introduction
        height: 501px

        &__image
            height: 501px
            object-fit: cover
        &__background
            height: 501px
            &-text
                top: 102px
                left: 32px
                width: 70%
                &-heading
                    font-size: 3.2rem
                    line-height: 4rem
                &-detail
                    font-size: 1.6rem
                    line-height: 2.4rem

    #solutions_a
        // margin-top: 46pxF
    .solutions
        &__heading
            font-size: 2.4rem
            line-height: 4rem
            width: 100%
            text-align: center
        &__list
            &-item-wrapper
                margin: 0 auto
                padding: 8px
            &-item
                margin: 0 auto
                width: 100%
                height: auto
                max-height: 410px
                gap: 0
                grid-gap: 0
                margin-bottom: 24px
                &-top
                    width: 295px
                    height: 107px
                    gap: 0
                    &-heading
                        width: 100%
                        display: block
                        text-align: center
                        font-size: 2rem
                        line-height: 4rem
                    &-descriptions
                        width: 100%
                        font-size: 1.6rem
                        line-height: 1.6rem
                    &-text
                        font-size: 1.4rem
                        line-height: 2.4rem
                        display: block
                &-img
                    display: block
                    width: 311px
                    height: 248px

    #member_a
        // margin-top: 60px

    .member
        gap: 0
        grid-gap: 0
        &__heading
            font-size: 2.4rem
            line-height: 4.0rem
        &__list

    .publications
        width: 90%
        padding: 0 16px
        padding-bottom: 26px
        &__heading
            font-size: 2.4rem
            line-height: 4rem
            width: 80%
            height: auto
            margin: 0 auto
            margin-top: 25px
            margin-bottom: 24px
        &__list
            &-left
                position: relative
                height: auto
                padding-bottom: 30px
                &-img
                    height: 189px
                &-heading
                    margin-bottom: 0
                    &-title
                        height: auto
                &-description
                    height: auto
                &::after
                    content: ""
                    width: 100%
                    height: 1px
                    position: absolute
                    bottom: 0
                    left: 0
                    background-color: #CCCCCC

            &-right
                margin-top: 26px
                height: auto
                &-img
                    width: 112px
                    height: 106px
                    margin-right: 10px
                &-info
                    width: auto
                    &-heading
                        margin: 0
                    &-time
                        margin: 0
                        line-height: 1.4rem
                        height: 14px
        &__btn
            justify-content: center

    .image-slider
        height: 501px
        object-fit: cover

    // .footer
    //     display: flex
    //     flex-direction: column
    //     align-items: flex-start
    //     height: 242px
    //     width: 90%
    //     padding: 30px 0
    //     gap: 0
    //     grid-gap: 0
    //     &__logo
    //         padding: 0
    //         width: 150px
    //         height: 32px
    //         display: flex
    //         align-items: center
    //         &-logo
    //             width: 20px
    //             height: 22px
    //         &-bkc-text
    //             font-size: 1.4rem
    //     &-sec2
    //         width: 100%
    //         padding: 0
    //     &__block
    //         height: 48px
    //         &-item
    //             font-size: 1.4rem
    //             line-height: 1.6rem

@media (min-width: 1024px) and (max-width: 1239px)
    .member__list
        max-width: 80%

@media (min-width: 1024px)
    .solution-bagri
        height: 646px
        & > img
            width: 109.5%
