body.colorlib-body {
    padding: 0;
    margin: 0;
}

#colorlib-notfound {
    height: 60vh;
    position: relative;
}

.colorlib-body #colorlib-notfound {
    height: 100vh;
}

#colorlib-notfound .colorlib-notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.colorlib-notfound {
    max-width: 767px;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 1.4;
    padding: 0 15px;
}

.colorlib-notfound .colorlib-notfound-404 {
    position: relative;
    height: 150px;
    line-height: 150px;
    margin-bottom: 25px;
}

.colorlib-notfound .colorlib-notfound-404 h1 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 150px;
    font-weight: 900;
    margin: 0px;
    text-transform: uppercase;
    background: url('https://res.cloudinary.com/drnl75uiy/image/upload/v1633773539/nhfbznezi9z0rvjs9xtr.png');
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
}

.colorlib-notfound .colorlib-notfound-404 h1:before,
.colorlib-notfound .colorlib-notfound-404 h1:after {
    display: none;
}

.colorlib-notfound h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0;
}

.colorlib-notfound h2:before,
.colorlib-notfound h2:after {
    display: none;
}

.colorlib-notfound p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.colorlib-notfound a {
    font-family: 'Titillium Web', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: #5c91fe;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.colorlib-notfound a:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 767px) {
    .colorlib-notfound .colorlib-notfound-404 {
        height: 110px;
        line-height: 110px;
    }

    .colorlib-notfound .colorlib-notfound-404 h1 {
        font-size: 120px;
    }
}

@media only screen and (max-height: 600px) {
    #colorlib-notfound {
        height: 100vh;
    }
}
