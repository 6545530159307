#header-wrapper
    display: flex
    justify-content: center
    height: 90px
    // background: rgba(255, 255, 255, 0.85)
    background: #fff
    z-index: 100000
    display: flex
    align-items: center
    width: 100%
    margin: 0 auto
    position: fixed
.header
    // width: 100%
    // padding: 0 149px 0 158px
    width: 80%
    // height: 32px
    display: flex
    justify-content: space-between
    align-items: center

    &__menu
        display: none
    &__bkc-logo
        display: flex
        width: 151px
        height: 32px
        // position: relative
        cursor: pointer
        &-logo
            // position: absolute
            width: 30px
            height: 32px
            left: 0px
            top: 0px
        &-bkc-text
            // position: absolute
            // width: 112px
            padding-left: 5px
            height: 32px
            left: 39px
            top: 0px
            font-weight: 700
            font-size: 24px
            line-height: 32px
            color: #000000

    &__nav
        display: flex
        flex-direction: row
        align-items: flex-start
        padding: 0px
        gap: 32px
        width: 532px
        height: 24px
        flex: none
        flex-grow: 0
        &-item
            text-align: center
            padding: 0px
            gap: 12px
            width: 84px
            height: 24px
            font-family: 'Inter'
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 24px
            color: #777E91
            &-btn-mobile
                display: none
            &--active
                color: #40a9ff

    &__select

    &__select
        height: 32px

        border: none
        // padding: 4px 0px 4px 8px
        background: #FCFCFD
        // width: 100.27px
        // height: 32px
        border: 1px solid #F4F5F6
        border-radius: 4px
        &:focus
            border: none
